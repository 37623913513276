<!-- 热销产品TOP10 -->
<template>
  <div>
    <div class="hot_product_top">
      <div class="title">
        <i class="iconfont icon-yuanhuan"></i>
        热销产品TOP10
      </div>
      <div class="time">
        <div
          v-for="(item, index) in offset"
          :key="index"
          :class="{ bright: currenIndex === index }"
        >
          近{{ item | positiveNumber }}日
        </div>
      </div>
    </div>
    <div class="hot_product_box">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="45%" />
          <col width="15%" />
        </colgroup>
        <thead>
          <th>排名</th>
          <th>产品名称（型号）</th>
          <th>销售数</th>
          <th>销售金额（元）</th>
        </thead>
      </table>
      <vue-seamless-scroll
        :data="dataList"
        :class-option="optionHover"
        class="seamless-warp"
        v-if="dataList"
        @ScrollEnd="ScrollEnd"
      >
        <ul>
          <li v-for="(item, index) in dataList" class="liStyle" :key="index">
            <span class="title1 text_align">
              <template>
                <img
                  v-if="index == 0"
                  class="image"
                  src="@/assets/image/hot_1.png"
                  alt=""
                />
                <img
                  v-if="index == 1"
                  class="image"
                  src="@/assets/image/hot_2.png"
                  alt=""
                />
                <img
                  v-if="index == 2"
                  class="image"
                  src="@/assets/image/hot_3.png"
                  alt=""
                />
                <span v-if="index > 2" class="title1 text_align">{{
                  (index + 1) | formattingNum
                }}</span>
              </template>
            </span>
            <span class="title2 text_align">{{ item.id }}</span>
            <span class="title3 text_align">{{ item.orderCount }}</span>
            <span class="title4 text_align"
              >￥{{ item.money | formatMoney }}元</span
            >
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import axios from "@/utils/request";

export default {
  data() {
    return {
      dataList: null,
      // 传输日期
      offset: [-7, -15, -30],
      currenIndex: 0,
    };
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.4, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 获取数据
    getHotProductData(offset) {
      const url = `/miniapp/scree/getExcellentGoodsRank?offset=${offset}`;
      axios.get(url).then(
        (res) => {
          this.dataList = res.data;
          // console.log(this.dataList);
        },
        (err) => {
          console.log("请求失败",err);
        }
      );
    },
    // 当轮播最上面的一条是本数组最后一条时，进行回调
    ScrollEnd() {
      this.currenIndex = (this.currenIndex + 1) % this.offset.length;
      this.getHotProductData(this.offset[this.currenIndex]);
    },
  },
  mounted() {
    this.getHotProductData(this.offset[0]);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
<style scoped>
* {
  color: #fff;
  font-size: 13px;
}
.hot_product_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.hot_product_top .title {
  font-size: 18px;
  font-weight: 600;
}

.hot_product_top .title i {
  color: #8cf0fe;
}

.hot_product_top .time {
  display: flex;
}
.hot_product_top .time > div {
  padding-right: 10px;
  cursor: pointer;
}

table {
  width: 100%;
  background-color: #1e408a;
  padding: 10px 5px;
  font-size: 18px;
}

th {
  font-size: 16px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-size: 20px;
}

li {
  box-sizing: border-box;
  align-items: center;
}

li span {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 28px;
  height: 28px;
}

.seamless-warp {
  height: 220px;
  overflow: hidden;
}

.liStyle {
  height: 48px;
  line-height: 48px;
  width: 100%;
  /* //设置flex布局，否则span标签无法设置宽度 */
  display: flex;
}
/* //设置宽度与表头列宽一致 */
.title1 {
  width: 10%;
}

.title2 {
  width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.title3 {
  width: 15%;
}
.title4 {
  width: 30%;
}

.text_align {
  text-align: center;
}

.bright {
  font-weight: 600;
  color: #8cf0fe;
}

.ranking {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 2px 3px;
  font-size: 10px;
}
</style>
