<!-- 全国总销量 -->
<template>
  <div id="chart" ref="chart" style="width: 100%; height: 100%"></div>
</template>
<script>
import echarts from "echarts";
import { autoHover } from "@/assets/toolitp";
import axios from '@/utils/request'

export default {
  data() {
    return {
      dataNum: null,
    };
  },
  methods: {
    // 获取数据
    getNationalSales() {
      axios.get("/miniapp/scree/getAllCountMoneyRank").then(
        (res) => {
          const data = res.data;
          this.dataNum = data;
        },
        (err) => {
          console.log("请求失败",err);
        }
      );
    },
    // 渲染图表
    renderChart() {
      const chart = this.$echarts.init(this.$refs.chart);
      const dataNum = this.dataNum;
      const option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#4B72CD",
          borderColor: "#3399FF",
          borderWidth: 1,
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            const index = params[0].dataIndex;
            const item = dataNum[index];
            // 自定义tooltip内容
            var txt = `
              <div style='color: '#fff'; font-size: 13px'>
                <div>
                  ${item.time}
                </div>
                <div>
                  订单：${item.orderCount}
                </div>
                <div>
                  金额：${item.money}万元
                </div>
                </div> 
            `;
            return txt;
          },
        },
        title: {
          text: "全国总销量",
          left: "center",
          textStyle: {
            color: "#fff",
            fontWeight: "bold",
            fontSize: "20px",
          },
        },
        // 图表布局设置
        grid: {
          bottom: "50px",
        },
        xAxis: {
          type: "category",
          data: this.dataNum.map((item) => item.time),
          // 是否显示x轴坐标刻度
          axisTick: {
            show: false,
          },
          axisLabel: {
            // 文字的配置
            show: true,
            interval: 0,
            color: "#fff",
          },
        },
        yAxis: {
          type: "value",
          name: "单位/万元",
          nameLocation: "end",
          nameTextStyle: {
            color: "#fff",
            padding: [0, 0, 0, 660],
          },
          splitLine: {
            lineStyle: {
              color: "rgba(240, 240, 240, 0.5)",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(240, 240, 240, 0.5)",
            },
          },
          axisLabel: {
            // 文字的配置
            show: true,
            interval: 0,
            color: "#fff",
          },
        },
        series: [
          {
            data: this.dataNum.map((item) => item.money),
            type: "line",
            smooth: true,
            symbolSize: 7,
            symbol: "circle",
            itemStyle: {
              color: "rgba(9, 21, 61, 0.84)",
              borderColor: "#0272FC",
            },
            lineStyle: {
              color: "#0272FC",
            },
          },
        ],
      };
      chart.setOption(option);
      this.tootipTimer && this.tootipTimer.clearLoop(); // this.tootipTimer 在data里定义
      this.tootipTimer = 1;
      const length = this.dataNum.length;
      this.tootipTimer = autoHover(chart, option, length, 3000);
    },
  },
  created() {
    this.getNationalSales();
  },
  mounted() {
    if (this.dataNum) {
      this.renderChart();
    } else {
      // 如果数据还没有准备好，可以在这里添加一个定时器或者事件监听器，等待数据准备好后再调用 renderChart
      setTimeout(() => {
        if (this.dataNum) {
          this.renderChart();
        }
      }, 1000); // 延迟1秒后再次尝试渲染图表
    }
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
<style>
#chart {
  padding-top: 18px;
  box-sizing: border-box;
}
</style>
