<!-- 门店数量排行 -->
<template>
  <div>
    <div class="title">门店数量排行</div>
    <vue-seamless-scroll
      :data="dataList"
      :class-option="optionHover"
      class="seamless-warp"
      v-if="dataList"
    >
      <div class="list">
        <div class="list_li" v-for="(item, index) in dataList" :key="index">
          {{ abbreviation(item.storeName) }}：<span>{{ item.totalCount }}</span
          >个，本月新增:<span>{{ item.newAddCount }}</span
          >个
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import axios from '@/utils/request'

export default {
  data() {
    return {
      dataList: null,
    };
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 获取数据
    getStoreNum() {
      axios.get("/miniapp/scree/getStoreCountRank").then(
        (res) => {
          this.dataList = res.data;
          // console.log(this.dataList);
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 省份名切割，只保留前面两字简称
    abbreviation(val) {
      return val.substring(0, 2);
    },
  },
  created() {
    this.getStoreNum()
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
<style scoped>
* {
  color: #fff;
  font-size: 18px;
}

.title {
  font-weight: 600;
  padding-bottom: 10px;
}

.list {
  height: 705px;
  overflow: hidden;
}

.seamless-warp {
  height: 240px;
  overflow: hidden;
}
.list_li span {
  color: #5050e1;
  font-size: 28px;
  font-weight: 600;
}
</style>
