<template>
  <div class="box">
    <div class="title">
      <img
        class="title_img"
        src="@/assets/image/loge.png"
        alt=""
        mode="widthFix"
      />
      <div class="title_txt">大事记</div>
    </div>
    <div class="cente">
      <div class="yeare">
        <div
          class="yeare_box"
          v-for="(item, index) in years"
          :key="index"
          :class="{ yeare_box_current: currenIndex === index }"
        >
          {{ item }}
        </div>
      </div>
      <div class="cent_box">
        <div class="box_line"></div>
        <vue-seamless-scroll
          :data="dataList"
          :class-option="optionHover"
          class="seamless-warp"
          v-if="dataList"
          @ScrollEnd="ScrollEnd"
        >
          <div class="box_list" v-for="(item, index) in dataList" :key="index">
            <div class="li_right_box" v-if="index % 2 == 0">
              <div class="dot dot_right"></div>
              <div class="li_right">
                <div class="txt_time">{{ item.publishTime }}</div>
                <div class="txt_text">{{ item.content }}</div>
              </div>
            </div>
            <div class="li_left_box" v-else>
              <div class="dot dot_left"></div>
              <div class="li_left">
                <div class="txt_time">{{ item.publishTime }}</div>
                <div class="txt_text">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/utils/request";

export default {
  data() {
    return {
      currenIndex: 0,
      dataList: null,
    };
  },
  computed: {
    // 获取从2019至今的年份，并用years存放
    years() {
      const startYear = 2019;
      const endYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = startYear; i <= endYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    },
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.4, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        limitMoveNum: 1, //开始滚动的数据量
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 获取数据
    getHistroy(year) {
      const url = `/system/events/listByYear?year=${year}`;
      axios.get(url).then(
        (res) => {
          this.dataList = res.data;
          // console.log(this.dataList);
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 当轮播最上面的一条是本数组最后一条时，进行回调
    ScrollEnd() {
      this.currenIndex = (this.currenIndex + 1) % this.years.length;
      this.getHistroy(this.years[this.currenIndex]);
    },
  },
  mounted() {
    this.getHistroy(this.years[0]);
  },
};
</script>
<style scoped>
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 14px;
  box-sizing: border-box;
}
.title {
  position: absolute;
  top: -55px;
  display: flex;
  align-items: center;
}
.title_img {
  width: 80px;
  margin-right: 10px;
}
.title_txt {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}

.cente {
  width: 100%;
  height: 100%;
}
.yeare {
  color: #fff;
  display: flex;
  font-size: 13px;
}
.yeare_box {
  margin-right: 18px;
  padding: 2px 4px;
  box-sizing: border-box;
}

.yeare_box_current {
  background-color: #015478;
  padding: 2px 4px;
  box-sizing: border-box;
  border-radius: 6px;
}

.cent_box {
  margin-top: 28px;
  box-sizing: border-box;
}

.box_line {
  height: 100%;
  border-left: 2px dotted #0085d9;
  position: absolute;
  right: 50%;
  z-index: 90;
  height: 200px;
}

.seamless-warp {
  position: absolute;
  z-index: 99;
  height: 195px;
  overflow: hidden;
}


.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #95f204;
}

.dot_left {
  position: absolute;
  top: 41%;
  left: -7.5%;
}

.dot_right {
  position: absolute;
  top: 40%;
  left: 49%;
}

.li_left_box {
  margin-left: 224px;
  position: relative;
}

.li_right_box {
  margin-left: -5px;
}

.li_left {
  margin: 7px;
  padding: 4px;
  width: 195px;
  border: 2px solid #3399ff;
  position: relative;
  background-color: rgba(194, 128, 266, 0.38);
  box-sizing: border-box;
}
.li_left::before {
  content: "";
  display: block;
  border-width: 12px;
  position: absolute;
  top: 30%;
  left: -12.5%;
  border-style: dashed solid solid dashed;
  border-color: transparent #3399ff transparent transparent;
  font-size: 0;
  line-height: 0;
}

.li_right_box {
  position: relative;
}

.li_right {
  margin: 7px;
  padding: 4px;
  width: 195px;
  border: 2px solid #3399ff;
  position: relative;
  background-color: rgba(194, 128, 266, 0.38);
  box-sizing: border-box;
}
.li_right::after {
  content: "";
  display: block;
  border-width: 12px;
  position: absolute;
  top: 32%;
  left: 100%;
  border-style: dashed solid solid dashed;
  border-color: transparent transparent transparent #3399ff;
  font-size: 0;
  line-height: 0;
}

.box_list {
  color: #fff;
  font-size: 16px;
}

.txt_time {
  font-weight: 600;
}
</style>
