<!-- 地图 -->
<template>
  <div>
    <div
      id="map_cn"
      class="chinaMap"
      style="width: 928px; height: 742px"
      ref="map"
    ></div>
  </div>
</template>
<script>
import china from "@/assets/map/china.json";
import axios from '@/utils/request'

export default {
  data() {
    return {
      index: 0,
      //地图中的数据
      dataList: [],
      //指定图表的配置项和数据
      option: {
        //标题组件
        layoutCenter: ["50%", "48%"], //位置
        layoutSize: "125%", //大小
        //提示框组件
        tooltip: {
          show: true,
          //触发类型：数据项图形触发
          trigger: "item",
          padding: [6, 10],
          borderWidth: 2,
          backgroundColor: "rgba(54, 54, 54, 0.8)",
          borderColor: "#409EFF",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          //提示框内容格式器
          formatter: (e) => {
            let context = "";
            //字符串模板
            let item = this.dataList.find(
              (item) => item.name.indexOf(e.name) == 0
            );
            if (item) {
              context = `
               <div>
                   <div>第${this.index + 1}名</div>
                   <div>${e.name}</div>
                   <div><span>门店数量 : </span><span>${
                     item.storeCount
                   }</span></div>
                   <div><span>交易金额 : </span><span>${
                     item.value
                   }元</span></div>
                   <div><span>成交订单数 : </span><span>${
                     item.orderCount
                   }单</span></div>
               </div>
             `;
            } else {
              e.value = 0;
              context = `
               <div>
                   <div>${e.name}</div>
                   <div><span>门店数量 : </span><span>0</span></div>
                   <div><span>交易金额 : </span><span>0元</span></div>
                   <div><span>成交订单数 : </span><span>0单</span></div>
               </div>
             `;
            }
            return context;
          },
        },
        //地理坐标系组件
        geo: {
          //使用 registerMap 注册的地图名称
          map: "china",
          //是否开启鼠标缩放和平移漫游
          roam: true,
          //当前视角缩放比例
          zoom: 1,
          //滚轮缩放的极限控制
          scaleLimit: {
            min: 1, //最小1倍
            max: 3, //最大3倍
          },
          //地图组件离容器的距离
          top: 90,
          left: "center",
          //图形上的文本标签
          label: {
            show: true,
            fontSize: "14",
            color: "#fff",
          },
          //地图区域的多边形 图形样式
          itemStyle: {
            borderColor: "#000", //图形的描边颜色
            borderWidth: 2, //描边线宽。为 0 时无描边
            borderType: "solid", // 边框样式
            opacity: 1, // 透明度
            shadowColor: "rgba(0, 0, 0, 0.2)",
            // shadowBlur: 10,
            // 高亮状态(鼠标移入后)的多边形和标签样式
          },
          emphasis: {
            label: {
              color: "#fff",
            },
            itemStyle: {
              areaColor: "#DFC32D",
            },
          },
          // 设置省份颜色
          regions: [
            {
              name: "北京",
              itemStyle: {
                areaColor: "#562895",
              },
            },
            {
              name: "天津",
              itemStyle: {
                areaColor: "#1FB2DF",
              },
            },
            {
              name: "河北",
              itemStyle: {
                areaColor: "#584DCE",
              },
            },
            {
              name: "山西",
              itemStyle: {
                areaColor: "#534BCC",
              },
            },
            {
              name: "内蒙古",
              itemStyle: {
                areaColor: "#4C48C7",
              },
            },
            {
              name: "辽宁",
              itemStyle: {
                areaColor: "#6552D5",
              },
            },
            {
              name: "吉林",
              itemStyle: {
                areaColor: "#6552D5",
              },
            },
            {
              name: "黑龙江",
              itemStyle: {
                areaColor: "#5030A2",
              },
            },
            {
              name: "上海",
              itemStyle: {
                areaColor: "#6B6B6B",
              },
            },
            {
              name: "江苏",
              itemStyle: {
                areaColor: "#5B228B",
              },
            },
            {
              name: "浙江",
              itemStyle: {
                areaColor: "#8C69CD",
              },
            },
            {
              name: "安徽",
              itemStyle: {
                areaColor: "#453BB4",
              },
            },
            {
              name: "福建",
              itemStyle: {
                areaColor: "#4646C5",
              },
            },
            {
              name: "江西",
              itemStyle: {
                areaColor: "#502FA1",
              },
            },
            {
              name: "山东",
              itemStyle: {
                areaColor: "#572794",
              },
            },
            {
              name: "河南",
              itemStyle: {
                areaColor: "#4344C3",
              },
            },
            {
              name: "湖北",
              itemStyle: {
                areaColor: "#4D32A5",
              },
            },
            {
              name: "湖南",
              itemStyle: {
                areaColor: "#5D50D1",
              },
            },
            {
              name: "广东",
              itemStyle: {
                areaColor: "#4D77DA",
              },
            },
            {
              name: "广西",
              itemStyle: {
                areaColor: "#5C4FD0",
              },
            },
            {
              name: "海南",
              itemStyle: {
                areaColor: "#594ECF",
              },
            },
            {
              name: "重庆",
              itemStyle: {
                areaColor: "#5F1E83",
              },
            },
            {
              name: "四川",
              itemStyle: {
                areaColor: "#3497DC",
              },
            },
            {
              name: "贵州",
              itemStyle: {
                areaColor: "#4243C1",
              },
            },
            {
              name: "云南",
              itemStyle: {
                areaColor: "#443CB6",
              },
            },
            {
              name: "西藏",
              itemStyle: {
                areaColor: "#4C78DA",
              },
            },
            {
              name: "陕西",
              itemStyle: {
                areaColor: "#3892DC",
              },
            },
            {
              name: "甘肃",
              itemStyle: {
                areaColor: "#25ABDE",
              },
            },
            {
              name: "青海",
              itemStyle: {
                areaColor: "#4243C2",
              },
            },
            {
              name: "宁夏",
              itemStyle: {
                areaColor: "#552996",
              },
            },
            {
              name: "新疆",
              itemStyle: {
                areaColor: "#514ACA",
              },
            },
            {
              name: "台湾",
              itemStyle: {
                areaColor: "#5F1E83",
              },
            },
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                areaColor: "#6B6B6B",
                borderColor: "#6B6B6B",
                borderWidth: 0,
              },
            },
          ],
        },
        series: [
          {
            type: "map",
            roam: true,
            geoIndex: 0,
            data: "",
            label: {
              show: true,
            },
          },
        ],
      },
    };
  },

  methods: {
    //定义方法 draw_map 绘制中国地图
    draw_map() {
      let myChart = this.$echarts.init(document.getElementById("map_cn"));
      this.$echarts.registerMap("china", china);
      this.getMapData();
      //高亮轮播展示
      var hourIndex = 0;
      var carouselTime = null;
      //setInterval() 可在每隔指定的毫秒数循环调用函数或表达式,直到clearInterval把它清除

      //使用setInterval方法后,必须使用箭头函数而不能写function,否则后续在该方法中无法调用data中的数据
      //carouselTime =setInterval(function(){ //错误写法

      carouselTime = setInterval(() => {
        if (this.dataList.length > 0) {
          let tempItem = this.dataList[this.index];
          //找到下标
          for (let i = 0; i < this.option.geo.regions.length; i++) {
            if (tempItem.name.indexOf(this.option.geo.regions[i].name) == 0) {
              hourIndex = i;
            }
          }
          //dispatchAction echarts的API：触发图表行为
          myChart.dispatchAction({
            type: "downplay", //downplay 取消高亮指定的数据图形
            seriesIndex: 0,
          });
          myChart.dispatchAction({
            type: "highlight", //highLight 高亮指定的数据图形
            seriesIndex: 0, //系列index

            dataIndex: hourIndex, //数据index
          });
          myChart.dispatchAction({
            type: "showTip", //showTip 显示提示框
            seriesIndex: 0,
            dataIndex: hourIndex,
          });
          if (this.index >= this.dataList.length - 1) {
            hourIndex = 0;
            this.index = 0;
          } else {
            this.index++;
          }
        }
        //当循环到数组当中的最后一条数据后，重新进行循环
      }, 3000);
      //鼠标移入组件时停止轮播
      myChart.on("mousemove", (e) => {
        clearInterval(carouselTime); //清除循环
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
      //鼠标移出组件时恢复轮播
      myChart.on("mouseout", () => {
        carouselTime = setInterval(() => {
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: hourIndex,
          });
          myChart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: hourIndex,
          });
          hourIndex++;
          if (hourIndex > this.dataList.length) {
            hourIndex = 0;
          }
        }, 3000);
      });

      //显示地图
      myChart.setOption(this.option);
    },
    // 获取数据
    getMapData() {
      axios.get("/miniapp/scree/getProvinceRank").then(
        (res) => {
          this.dataList = res.data;
          // console.log("map", this.dataList);
        },
        (err) => {
          console.log("请求失败");
        }
      );
    },
  },
  created() {
    this.getMapData();
    // this.option.series[0].data = this.dataList;
  },
  mounted() {
    this.$nextTick(() => {
      this.draw_map();
    });
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
