import Vue from "vue";
import App from "./App.vue";
// 大屏自适应
import VScaleScreen from "v-scale-screen";
// echarts
import * as echarts from "echarts";
// iconfont
import "@/assets/iconfont/iconfont.css";
// 列表无缝轮播
import scroll from "vue-seamless-scroll";
// 过滤器
import filters from "./utils/filters";
// 路由
import router from "./router/index";
// element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// axios配置
// import axios from '@/utils/request'

Vue.use(ElementUI);
Vue.use(VScaleScreen);
Vue.use(scroll);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
// Vue.prototype.$http = axios

// 注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  render: (h) => h(App),
  router
}).$mount("#app");
