import axios from "axios";
import router from "@/router";

// 基本配置
const instance = axios.create({
  // timeout: 50000, // 设置超时时间，单位为ms
  baseURL: '/uat-api',
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = token; // 设置请求头部分，这里使用了localStorage存储的token作为身份标识
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      // 清除本地存储的token
      localStorage.removeItem("token");
      // 跳转到登录页面
      router.push("/login");
    } else if (response.status === 200 || response.data.code === 200) {
      return response.data;
    } else {
      // 如果响应状态码不是200，抛出错误
      // throw new Error("请求失败");
    }
  },
  (error) => {
    console.log(error);
    // 判断是否为token过期的错误
    if (error.response && error.response.status === 401) {
      // 清除本地存储的token
      localStorage.removeItem("token");
      // 跳转到登录页面
      router.push("/login");
    } else {
      // 其他错误处理
      console.error("Error:", error);
    }
    return Promise.reject(error);
  }
);

export default instance;
