export default {
  // 金额格式化
  formatMoney(value) {
    if (!value) return "";
    value = parseFloat(value);
    if (isNaN(value)) return "";
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  // 负数的绝对值
  positiveNumber: function (value) {
    return Math.abs(value);
  },
  // 格式化排行数字,大于10不处理，小于10前面加0
  formattingNum(value) {
    if (value < 10) {
      return "0" + value;
    }
    return value;
  },
  // 保留两位小数（向下取整）
  floorNum(value) {
    return Math.floor(value)
  }
};
