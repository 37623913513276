<!-- 门店当月进货金额排行 -->
<template>
  <div>
    <table>
      <!-- 设置列宽 -->
      <colgroup>
        <col width="10%" />
        <col width="45%" />
        <col width="15%" />
      </colgroup>
      <thead>
        <th>排名</th>
        <th>门店名称</th>
        <th>订单数</th>
        <th>进货金额</th>
      </thead>
    </table>
    <vue-seamless-scroll
      :data="dataNum"
      :class-option="optionHover"
      class="seamless-warp"
      v-if="dataNum"
    >
      <ul>
        <li v-for="(item, index) in dataNum" class="liStyle" :key="index">
          <span class="title1 text_align">
            <template>
              <i
                v-if="index <= 2"
                class="iconfont icon-huangguan"
                :class="getInconColor(index)"
              ></i>
              <span v-else class="title1 text_align ranking">{{
                (index + 1) | formattingNum
              }}</span>
            </template>
          </span>
          <span class="title2 text_align">{{ item.storeName }}</span>
          <span class="title3 text_align">{{ item.orderCount }}</span>
          <span class="title4 text_align"
            >{{ item.money | formatMoney }}元</span
          >
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import axios from '@/utils/request'

export default {
  data() {
    return {
      dataNum: null,
    };
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 获取数据
    getStoreData() {
      axios.get("/miniapp/scree/getSaleByMonthRank").then(
        (res) => {
          this.dataNum = res.data;
          // console.log(this.dataNum);
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 修改icon颜色
    getInconColor(index) {
      if (index == 0) {
        return "iconColor1";
      } else if (index == 1) {
        return "iconColor2";
      } else {
        return "iconColor3";
      }
    },
  },
  created() {
    this.getStoreData()
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  /*font-weight: normal;*/
}

ul {
  list-style: none;
}

li {
  padding: 0 5px;
  box-sizing: border-box;
}

table {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
}

th {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background-color: rgba(9, 21, 61, 0.1);
}

.seamless-warp {
  height: 190px;
  overflow: hidden;
}
.liStyle {
  height: 40px;
  line-height: 40px;
  width: 100%;
  /* //设置flex布局，否则span标签无法设置宽度 */
  display: flex;
}
/* //设置宽度与表头列宽一致 */
.title1 {
  width: 10%;
}

.title2 {
  width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.title3 {
  width: 15%;
}
.title4 {
  width: 30%;
}

.text_align {
  text-align: center;
}

.icon-huangguan {
  font-size: 20px;
}

.ranking {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 2px 3px;
  font-size: 10px;
}

.iconColor1 {
  color: #fecb30;
}
.iconColor2 {
  color: #e8e8e8;
}
.iconColor3 {
  color: #c7915d;
}
</style>
