<template>
  <v-scale-screen width="1920" height="1080">
    <div class="box">
      <!-- 标题栏 -->
      <div class="top">
        <img class="top_img" src="@/assets/image/daping_title.png" alt="" />
        <div class="top_txt">
          <div class="top_title">纤丽兰心全国数据大屏</div>
          <div class="top_data">
            <!-- 实时时间 -->
            <div class="top_data_date">
              <div class="year">
                <span>{{ year }}</span
                >年<span>{{ month }}</span
                >月<span>{{ day }}</span
                >日
              </div>
              <div class="week">星期{{ week }}</div>
              <div class="time">{{ time }}</div>
            </div>
            <!-- 天气播报 -->
            <div class="top_data_weather">
              <i class="iconfont icon-dingwei"></i>
              <div class="city">{{ weather.cityName }}</div>
              <i class="iconfont icon-tianqi"></i>
              <div class="weather">{{ weather.type }}</div>
              <div class="temperature">
                {{ weather.low }}℃/{{ weather.high }}℃
              </div>
            </div>
          </div>
          <!-- 数据更新时间 -->
          <div class="top_newdate">
            <i class="iconfont icon-xunhuan"></i>
            <span>数据更新时间</span>
            {{ year }}-{{ month }}-{{ day }}
          </div>
        </div>
      </div>
      <!-- 主体部分 -->
      <div class="center">
        <!-- 左边图表 -->
        <div class="center_left">
          <!-- 门店当月销售金额排行榜 -->
          <div class="center_left_box store_sales">
            <div class="title">门店当月进货金额排行榜</div>
            <div class="table">
              <StoreSales></StoreSales>
            </div>
          </div>
          <!-- 当月团队排行TOP10 -->
          <div class="center_left_box team_ranking">
            <div class="title">当月团队排行TOP10</div>
            <div class="shadow">
              <TeamRanking></TeamRanking>
            </div>
          </div>
          <!-- 门店服务顾客数排行TOP10 -->
          <div class="center_left_box store_service">
            <div class="title">门店服务顾客次数排行TOP10</div>
            <div class="service_table">
              <StoreService></StoreService>
            </div>
          </div>
        </div>
        <!-- 中部图表 -->
        <div class="center_middle">
          <!-- 总数据集 -->
          <div class="total_data">
            <div
              class="total_data_box"
              v-for="(item, index) in totalData"
              :key="index"
            >
              <div class="name">{{ item.name }}：</div>
              <div class="number">
                {{ item.number }}
                <span v-if="index < 2">个</span>
                <span v-else>人</span>
              </div>
            </div>
          </div>
          <!-- 服务客户历史数据集 -->
          <div class="history_data center_middle_little">
            <div
              class="history_data_box"
              v-for="(item, index) in historyData"
              :key="index"
            >
              <div class="name">{{ item.name }}</div>
              <div class="number_box">
                <div
                  class="number"
                  v-for="(citem, cindex) in item.num"
                  :key="cindex"
                >
                  {{ citem }}
                </div>
                <span>人</span>
              </div>
            </div>
          </div>
          <!-- 地图数据 -->
          <div class="map">
            <MyMap></MyMap>
          </div>
          <!-- 门店数量 -->
          <div class="store_Number">
            <StoreNumber></StoreNumber>
          </div>
        </div>
        <!-- 右部图表 -->
        <div class="center_right">
          <!-- 兰心大事迹 -->
          <div class="lanxin_history">
            <LanHistory></LanHistory>
          </div>
          <!-- 全国总销量 -->
          <div class="national_sales center_left_box">
            <NationalSales></NationalSales>
          </div>
          <!-- 热销产品TOP10 -->
          <div class="hot_product">
            <HotProduct></HotProduct>
          </div>
        </div>
      </div>
    </div>
  </v-scale-screen>
</template>
<style scoped>
@import url("./HomeIndex.css");
</style>
<script>
import axios from "@/utils/request";
import StoreSales from "@/components/StoreSales.vue";
import MyMap from "@/components/MyMap.vue";
import StoreNumber from "@/components/StoreNumber.vue";
import TeamRanking from "@/components/TeamRanking.vue";
import StoreService from "@/components/StoreService.vue";
import LanHistory from "@/components/LanHistory.vue";
import NationalSales from "@/components/NationalSales.vue";
import HotProduct from "@/components/HotProduct.vue";

export default {
  components: {
    StoreSales,
    MyMap,
    StoreNumber,
    TeamRanking,
    StoreService,
    LanHistory,
    NationalSales,
    HotProduct,
  },
  data() {
    return {
      // 年月日
      year: "",
      month: "",
      day: "",
      // 星期
      week: "",
      // 时分秒
      time: "",
      // 天气
      weather: {},
      // 门店当月销售金额排行榜
      storeSales: [],
      // 总数据集
      totalData: [
        { name: "城市", number: "1234" },
        { name: "门店", number: "1234" },
        { name: "兰心人", number: "5638" },
        { name: "客户", number: "359287" },
      ],
      // 服务客户历史数据集
      historyData: [
        { name: "上月服务客户数量", num: "120394" },
        { name: "上周服务客户数量", num: "25067" },
        { name: "昨日服务客户数量", num: "6372" },
      ],
    };
  },
  mounted() {
    // 实时获取时间
    this.updateTime();
    setInterval(this.updateTime, 100);
    // 实时获取天气
    this.getWeather();
    setInterval(this.getWeather, 10000);
    // 获取全国门店数量
    this.getTotalData();
    // 获取门店服务顾客数
    // this.getHistoryData();
  },
  methods: {
    // 实时获取时间
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleDateString();
      this.year = now.getFullYear();
      this.month = now.getMonth() + 1;
      this.day = now.getDate();
      this.week = "日一二三四五六".charAt(now.getDay());
      // 获取时分秒
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      // 格式化为xx:xx:xx的格式
      this.time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
    // 获取天气信息
    getWeather() {
      axios.get("/miniapp/scree/getWeatherInfo").then(
        (res) => {
          const weatherData = res.data;
          this.weather = weatherData;
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 获取全国门店数量
    getTotalData() {
      axios.get("/miniapp/scree/getCityStoreTotal").then(
        (res) => {
          const total = res.data;
          for (let item of this.totalData) {
            if (item.name == "城市") {
              item.number = total.city;
            } else if (item.name == "门店") {
              item.number = 1289 + total.store;
            }
          }
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 获取门店服务客户数
    getHistoryData() {
      axios.get("/miniapp/scree/getStoreSvcUser").then(
        (res) => {
          console.log("res", res.data);
          this.historyData[0].num = res.data.lastMonthServiceSum.toString();
          this.historyData[1].num = res.data.lastWeekServiceSum.toString();
          this.historyData[2].num = res.data.yesterdayServiceSum.toString();
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
  },
};
</script>
