<!-- 门店服务顾客次数排行 -->
<template>
  <div>
    <div class="box">
      <div class="top">
        <div
          v-for="(item, index) in offset"
          :key="index"
          :class="{ bright: currenIndex === index }"
        >
          近{{ item | positiveNumber }}日
        </div>
      </div>
      <div class="table">
        <table>
          <!-- 设置列宽 -->
          <colgroup>
            <col width="33.5%" />
            <col width="33.5%" />
            <col width="33.5%" />
          </colgroup>
          <thead>
            <th>团队</th>
            <th>门店</th>
            <th>服务顾客次数</th>
           <!--  <th>趋势</th> -->
          </thead>
        </table>
        <div>
          <vue-seamless-scroll
            :data="dataList"
            :class-option="optionHover"
            class="seamless-warp"
            v-if="dataList"
            @ScrollEnd="ScrollEnd"
          >
            <ul>
              <li
                v-for="(item, index) in dataList"
                class="liStyle"
                :key="index"
              >
                <span class="title2 text_align">{{ item.teamName }}</span>
                <span class="title2 text_align">{{ item.storeName }}</span>
                <span class="text_align">{{ item.serviceCount }}</span>
               <!--  <template>
                  <span v-if="item.trend > 0" class="text_align up_color">
                    <i class="iconfont icon-shangjiantou up_color"></i>
                    {{ item.trend }}
                  </span>
                  <span v-else-if="item.trend == 0" class="text_align up_color">
                    {{ item.trend }}
                  </span>
                  <span v-else class="text_align down_color">
                    <i class="iconfont icon-arr-down down_color"></i>
                    {{ item.trend }}
                  </span>
                </template> -->
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/utils/request";

export default {
  data() {
    return {
      dataList: null,
      offset: [-7, -15, -30],
      currenIndex: 0,
    };
  },
  computed: {
    //滚动表格的配置项
    optionHover() {
      return {
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        step: 0.5, // 数值越大速度滚动越快
        openWatch: true, // 开启数据实时监控刷新dom
        // limitMoveNum: 5, //开始滚动的数据量
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 获取数据
    getStoreService(offset) {
      const url = `/miniapp/scree/getServiceRankByStore?offset=${offset}`;
      axios.get(url).then(
        (res) => {
          this.dataList = res.data;
          // console.log(this.dataList);
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },
    // 当轮播最上面的一条是本数组最后一条时，进行回调
    ScrollEnd() {
      this.currenIndex = (this.currenIndex + 1) % this.offset.length;
      this.getStoreService(this.offset[this.currenIndex]);
    },
  },
  mounted() {
    this.getStoreService(this.offset[0]);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
<style scoped>
* {
  color: #fff;
}

.box {
  padding: 0 10px;
  box-sizing: border-box;
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.top > div {
  margin-left: 25px;
}
.table {
  padding: 10px 0;
  box-sizing: border-box;
}

table {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}

th {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  background-color: rgba(21, 56, 99, 0.5);
  border: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li:last-child {
  border-bottom: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}

li {
  padding: 0;
  box-sizing: border-box;
}

li > span {
  border-left: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}

.liStyle span:last-child {
  border-right: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}
.seamless-warp {
  height: 160px;
  overflow: hidden;
}
.liStyle {
  height: 40px;
  line-height: 40px;
  width: 100%;
  /* //设置flex布局，否则span标签无法设置宽度 */
  display: flex;
  border-top: 1px solid rgba(121, 121, 121, 0.5);
  box-sizing: border-box;
}

/* //设置宽度与表头列宽一致 */

.title2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 4px;
  box-sizing: border-box;
}

.text_align {
  width: 33.5%;
  text-align: center;
}

.bright {
  font-weight: 600;
  color: #8cf0fe;
}

.up_color {
  color: #00cc99;
}

.down_color {
  color: #ff3300;
}
</style>
