import Vue from "vue";
import VueRouter from "vue-router";
import LogIn from "@/view/LogIn.vue";
import Home from "@/view/HomeIndex/HomeIndex.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: LogIn,
  },
];

const router = new VueRouter({
  mode: "history", // 使用 history 模式，去掉 URL 中的 #
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !localStorage.getItem("token")) {
    next("/login"); // 如果需要认证但未登录，重定向到登录页面
  } else {
    next(); // 否则继续导航
  }
});

export default router;
