<template>
  <v-scale-screen>
    <div class="box">
      <img class="bg_img" src="@/assets/image/bg.png" alt="" />
      <div class="title">纤丽兰心数据分析平台</div>
      <div class="form">
        <el-form
          :model="loginForm"
          :rules="rules"
          label-width="0px"
          class="login_form"
          ref="LoginFormRef"
        >
          <!-- 用户 -->
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              prefix-icon="iconfont icon-yonghu"
              placeholder="用户"
              class="form_type"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              prefix-icon="iconfont icon-mima"
              placeholder="密码"
              type="password"
              class="form_type"
            ></el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item prop="code" class="el_input_last">
            <div class="code_box">
              <el-input
                v-model="loginForm.code"
                prefix-icon="iconfont icon-yanzhengma"
                placeholder="验证码"
                class="form_type code_type"
              ></el-input>
              <div class="code_img_box form_type" @click="getcode">
                <img
                  class="code_img"
                  :src="codeList.img"
                  alt=""
                  mode="widthFix"
                />
              </div>
            </div>
          </el-form-item>
          <!-- 记住密码 -->
          <el-checkbox v-model="checked" class="check_box"
            >记住密码</el-checkbox
          >
          <!-- 登陆 -->
          <el-form-item class="btns">
            <el-button type="primary" round @click="handleLogin"
              >登&nbsp;陆</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </v-scale-screen>
</template>

<script>
import router from "@/router";
import axios from "@/utils/request";

//导入登录接口模块
// import { loginAPI } from "@/api";
export default {
  data() {
    return {
      // 是否记住密码
      checked: false,
      // 验证码对象
      codeList: {},
      // 表单数据对象
      loginForm: {
        username: null,
        password: null,
        code: null,
        uuid: null,
      },
      // 表单数据验证规则
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 获取验证码图片
    getcode() {
      axios.get("/code").then((res) => {
        const newCode = res;
        newCode.img = "data:image/png;base64," + newCode.img;
        this.codeList = newCode;
        this.loginForm.uuid = newCode.uuid;
      });
    },
    // 登陆
    handleLogin() {
      if (!this.loginForm.username) {
        alert("用户名不能为空");
        return;
      }
      if (!this.loginForm.password) {
        alert("密码不能为空");
        return;
      }
      if (!this.loginForm.code) {
        alert("验证码不能为空");
        return;
      }
      // 是否记住密码
      if (this.checked) {
        localStorage.setItem("username", this.loginForm.username);
        localStorage.setItem("password", this.loginForm.password);
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      }
      axios
        .post("/auth/login", this.loginForm)
        .then((res) => {
          if (res.code != 200) {
            alert(res.msg)
            this.getcode()
          } else {
            localStorage.setItem("token", res.data.access_token)
            router.push("/")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getcode();
    // 判断本地缓存是否有用户名及密码
    let strName = localStorage.getItem("username");
    let strPass = localStorage.getItem("password");
    if (strName) {
      this.loginForm.username = strName;
      this.checked = true;
    }
    if (strPass) {
      this.loginForm.password = strPass;
      this.checked = true;
    }
  },
};
</script>
<style scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bg_img {
  position: absolute;
  z-index: -99;
}
.title {
  color: #12e1fb;
  font-size: 55px;
  font-weight: 600;
  text-align: center;
  padding-top: 80px;
}
.form {
  color: #fff;
  width: 340px;
  margin-top: 300px;
}

.form_type {
  border: 3px solid #0893db;
  border-radius: 4px;
  box-sizing: border-box;
}

.el-input /deep/ .el-input__inner {
  background: none;
  border: none;
  color: #12e1fb;
}

.el-input--prefix /deep/ .el-input__inner {
  padding-left: 38px;
}

.el-input /deep/ .el-input__icon {
  color: #66ffff;
  font-size: 24px;
  margin-right: 7px;
}

.el-input /deep/.el-input__inner::placeholder {
  color: #66ffff;
  font-size: 16px;
}

.el_input_last {
  margin-bottom: 0 !important;
}

.check_box {
  margin: 20px 0;
  color: #66ffff;
  font-size: 16px;
}

.el-checkbox /deep/.el-checkbox__input {
  border: 1px solid;
  box-sizing: border-box;
}
.el-checkbox /deep/.el-checkbox__input .el-checkbox__inner {
  border: none;
  background: none;
  box-sizing: border-box;
}

/* 选中的样式 */
.el-checkbox /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  left: 5px;
  top: 3px;
  border: 1px solid #66ffff;
  border-left: 0;
  border-top: 0;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #66ffff;
}

.code_box {
  display: flex;
  justify-content: space-between;
}

.code_img_box {
  width: 82px;
  display: flex;
  align-items: center;
}

.code_img {
  width: 76px;
  height: 40px;
}

.code_type {
  width: 245px;
}

.el-button {
  width: 340px;
  height: 52px;
  padding: 0 !important;
  border-radius: 4px !important;
  color: #fff;
  background-color: #0992da;
  border: none !important;
  font-size: 18px;
  font-weight: bold;
}
</style>
