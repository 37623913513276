<!-- 当月团队排行 -->
<template>
  <div>
    <div ref="myChart" style="width: 458px; height: 243px"></div>
  </div>
</template>
<script>
import axios from "@/utils/request";

export default {
  data() {
    return {
      dataList: null,
    };
  },
  methods: {
    // 获取数据
    getTeamData() {
      axios.get("/miniapp/scree/getTeamRank").then(
        (res) => {
          this.dataList = res.data.reverse();
          // console.log(this.dataList);
        },
        (err) => {
          console.log("请求失败", err);
        }
      );
    },

    // 渲染图表
    renderChart() {
      const myChart = this.$echarts.init(this.$refs.myChart);
      const option = {
        dataZoom: [
          {
            // 第一个 dataZoom 组件
            type: "inside",
            yAxisIndex: 0,
            startValue: this.dataList.length, // 数据窗口范围的起始数值index
            endValue: 6, // 数据窗口范围的结束数值index
          },
        ],
        // 图表布局设置
        grid: {
          left: "70px",
          top: "10px",
          bottom: "10px",
          right:"60px",
        },
        xAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: this.dataList.map((item) => item.directorName),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
            fontSize: "14px",
          },
        },
        series: [
          {
            name: "销售额",
            type: "bar",
            data: this.dataList.map((item) => item.money),
            barWidth: "25px",
            label: {
              show: true,
              position: "right",
              formatter: function (data) {
                return data.value + "万元";
              },
              color: "#fff",
              // align: 'right',
              borderColor: "none",
            },
            itemStyle: {
              color: "#F43031",
            },
          },
        ],
      };
      option && myChart.setOption(option);
      setInterval(() => {
        if (option.dataZoom[0].endValue == 0) {
          option.dataZoom[0].startValue = this.dataList.length;
          option.dataZoom[0].endValue = 6;
        } else {
          option.dataZoom[0].startValue = option.dataZoom[0].startValue - 1;
          option.dataZoom[0].endValue = option.dataZoom[0].endValue - 1;
        }
        myChart.setOption(option);
      }, 2000);
    },
  },
  created() {
    this.getTeamData();
  },
  mounted() {
    if (this.dataList) {
      this.renderChart();
    } else {
      // 如果数据还没有准备好，可以在这里添加一个定时器或者事件监听器，等待数据准备好后再调用 renderChart
      setTimeout(() => {
        if (this.dataList) {
          this.renderChart();
        }
      }, 1000); // 延迟1秒后再次尝试渲染图表
    }
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器
  },
};
</script>
